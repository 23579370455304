import { NorthernToolSidebarItems } from '@/app/config/sidebar/NorthernToolSidebarItems';
import { RetailerConfig } from '@/app/types/models';
import {
  LEGACY_MY_PROJECTS_URL_ACEHARDWARE,
  LEGACY_MY_PROJECTS_URL_BJS,
  LEGACY_MY_PROJECTS_URL_CARPETONE,
  LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  LEGACY_MY_PROJECTS_URL_FLOORINGAMERICA,
  LEGACY_MY_PROJECTS_URL_LOWES,
  LEGACY_MY_PROJECTS_URL_NORTHERNTOOL,
  LEGACY_MY_PROJECTS_URL_PROSOURCE,
  LEGACY_MY_PROJECTS_URL_RONAHOMESERVICES,
  LEGACY_MY_PROJECTS_URL_SAMS,
  SrcPartnerEnum,
  LEGACY_MY_PROJECTS_URL_DIRECT,
} from '@/app/utils/constants';
import BjsLogo from '../components/images/BjsLogo';
import ConfidentRemodelsLogo from '../components/images/ConfidentRemodelsLogo';
import ImeLogo from '../components/images/ImeLogo';
import LowesLogo from '../components/images/LowesLogo';
import NorthernToolLogo from '../components/images/NorthernTool';
import RonaLogo from '../components/images/RonaLogo';
import SamsLogo from '../components/images/SamsLogo';
import AceTheme from '../components/layouts/themes/AceTheme';
import BjsTheme from '../components/layouts/themes/BjsTheme';
import ConfidentRemodelsTheme from '../components/layouts/themes/ConfidentRemodelsTheme';
import ImeTheme from '../components/layouts/themes/ImeTheme';
import LowesTheme from '../components/layouts/themes/LowesTheme';
import NothernToolTheme from '../components/layouts/themes/NorthernTool';
import RonaTheme from '../components/layouts/themes/RonaTheme';
import { getFooterLinksByRetailer } from './footer/footerLinks';
import { AceSidebarItems } from './sidebar/AceSidebarItems';
import { BJsSidebarItems } from './sidebar/BjsSidebarItems';
import { FnDSidebarItems } from './sidebar/FnDSidebarItems';

const lowesConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.Lowes,
  placeholderImageUrl: 'lowes_placeholder.png',
  faviconUrl: 'lowes_favicon.ico',
  name: "Lowe's",
  logo: <LowesLogo />,
  theme: LowesTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_LOWES,
  accessibilityStatement: 'https://lowes.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771115',
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.Lowes),
};

const samsConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.Sams,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'sams_favicon.ico',
  name: 'Sams',
  logo: <SamsLogo />,
  theme: ConfidentRemodelsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_SAMS,
  accessibilityStatement: 'https://confidentremodels.com/samsclub/about/accessibility-statement',
  phoneNumber: '8774771121',
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.Sams),
};

const fndConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.FND,
  placeholderImageUrl: 'ime_placeholder.png',
  faviconUrl: 'ime_favicon.ico',
  name: 'Floor and Decor',
  logo: <ImeLogo />,
  theme: ImeTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  accessibilityStatement: 'https://pros.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771017',
  sidebarItems: FnDSidebarItems,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.FND),
};

const bjsConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.BJs,
  placeholderImageUrl: 'bjs_placeholder.png',
  faviconUrl: 'bjs_favicon.ico',
  name: 'BJs',
  logo: <BjsLogo />,
  theme: BjsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_BJS,
  phoneNumber: '8772574747',
  sidebarItems: BJsSidebarItems,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.BJs),
};

const aceConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.AceHardware,
  placeholderImageUrl: 'ace_placeholder.png',
  faviconUrl: 'ace_favicon.ico',
  name: 'Ace',
  logo: <ImeLogo />,
  theme: AceTheme,
  phoneNumber: '8669104833',
  sidebarItems: AceSidebarItems,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_ACEHARDWARE,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.AceHardware),
};

const proSourceConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.ProSource,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449026988',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_PROSOURCE,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.ProSource),
};

const flooringAmericaConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.FlooringAmericaInHome,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449026467',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_FLOORINGAMERICA,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.FlooringAmericaInHome),
};

const carpetOneConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.CarpetOne,
  placeholderImageUrl: 'confident_remodels_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Confident Remodels',
  logo: <ConfidentRemodelsLogo />,
  theme: ConfidentRemodelsTheme,
  phoneNumber: '8449027100',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_CARPETONE,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.CarpetOne),
};

const northernToolConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.NorthernTool,
  placeholderImageUrl: 'northern_tool_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Northern Tool',
  logo: <NorthernToolLogo />,
  theme: NothernToolTheme,
  phoneNumber: '8662080054',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_NORTHERNTOOL,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.NorthernTool),
  sidebarItems: NorthernToolSidebarItems,
};

const ronaHomeServicesConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.RonaHomeServices,
  placeholderImageUrl: 'rona_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Rona Home Services',
  logo: <RonaLogo />,
  theme: RonaTheme,
  phoneNumber: '8665445833',
  emailAddress: 'customercare@confidentmodels.com',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_RONAHOMESERVICES,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.RonaHomeServices),
};

const directConfig: RetailerConfig = {
  srcPartnerId: SrcPartnerEnum.Direct,
  placeholderImageUrl: 'ime_placeholder.png',
  faviconUrl: 'favicon.ico',
  name: 'Direct',
  logo: <></>,
  theme: ImeTheme,
  phoneNumber: '',
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_DIRECT,
  footerLinks: getFooterLinksByRetailer(SrcPartnerEnum.Direct),
  disableChat: true,
  useProviderPhoneNumberAsRetailerPhone: true
};

export default [
  lowesConfig,
  samsConfig,
  fndConfig,
  aceConfig,
  bjsConfig,
  proSourceConfig,
  flooringAmericaConfig,
  carpetOneConfig,
  northernToolConfig,
  ronaHomeServicesConfig,
  directConfig,
];
