import React from 'react';
import NteLogo from '@/public/images/NteLogo.jpg';

export default function NorthernToolLogo() {
  return (
    <img
      style={{ maxWidth: '100%', height: 'auto', width: 'min(100%, 220px)', marginTop: '-10px' }}
      src={NteLogo.src}
      alt="Northern Tool Installation Logo"
    />
  );
}
