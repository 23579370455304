import { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import useTracking from '@/app/hooks/useTracking';
import { openPDF, replaceEnvInUrl } from '@/app/utils/helpers';
import {
  ButtonEventProperties,
  ButtonEvents,
  NavEventProperties,
} from '@/app/utils/trackingEvents';
import { useTranslations } from 'next-intl';
import { BsCart, BsFileSpreadsheet } from 'react-icons/bs';

const enum NorthernToolSidebarLinks {
  // Products
  GENERATORS = `https://northerntoolinstallation.com/products/generators/`,

  // Measure & Estimate
  FREE_ESTIMATE = 'https://northerntoolinstallation.com/stand-by-generators/',
}

export const NorthernToolSidebarItems = (): SidebarItemProps[] => {
  const t = useTranslations('components.navMenu.sidebar');
  const { events, trackClick } = useTracking();

  return [
    {
      id: '1',
      name: t('buttons.Products'),
      isCollapsible: true,
      icon: <BsCart size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Generators`),
          onClick: () => {
            openPDF(NorthernToolSidebarLinks.GENERATORS);
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: NorthernToolSidebarLinks.GENERATORS,
              category: t(`buttons.Generators`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '2',
      name: t('buttons.Free Estimate'),
      isCollapsible: false,
      icon: <BsFileSpreadsheet size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(NorthernToolSidebarLinks.FREE_ESTIMATE);
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: NorthernToolSidebarLinks.FREE_ESTIMATE,
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
  ];
};
