import { createContext, ReactNode, useEffect, useState } from 'react';
import { Category, FeatureFlags, RetailerConfig } from '@/app/types/models';
import { getRetailerConfig, getRetailerMappingByHostname } from './services/retailerService';
import { SrcPartnerEnum } from './utils/constants';

type Props = {
  children: ReactNode;
};

export type AppData = {
  categories: Category[];
  srcPartnerId: SrcPartnerEnum;
  features: FeatureFlags;
  displayPhoneNumber?: string;
  retailerConfig?: RetailerConfig;
  setCategories: (categories: Category[]) => void;
  getCategoryNameById: (value: number) => string;
  setFeatures: (features: Partial<FeatureFlags>) => void;
  setSrcPartnerId: (srcPartnerId: number) => void;
  setDisplayPhoneNumber: (phoneNumber: string | undefined) => void;
  setRetailerConfig: (retailerConfig: RetailerConfig) => void;
};

export const AppContext = createContext<AppData>({
  categories: [],
  srcPartnerId: SrcPartnerEnum.None,
  features: {
    ablyChat: false,
    useRetailerLevelSMSOptIn: false,
  },
  setCategories: () => null,
  getCategoryNameById: () => '',
  setFeatures: () => null,
  setSrcPartnerId: () => null,
  setDisplayPhoneNumber: () => null,
  setRetailerConfig: () => null,
});

export default function AppProvider({ children }: Props) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [srcPartnerId, setSrcPartnerId] = useState<number>(0);
  const [features, setFeatures] = useState({});
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState<string | undefined>();
  const [retailerConfig, setRetailerConfig] = useState<RetailerConfig | undefined>();

  useEffect(() => {
    /** This is to make sure, that window fetch is done in the client to prevent build errors */
    const srcPartnerId = getRetailerMappingByHostname(window.location.hostname).srcPartnerId;
    setSrcPartnerId(srcPartnerId);
    const retailerConfig = getRetailerConfig(srcPartnerId);
    setRetailerConfig(retailerConfig);

    // Set a displayPhoneNumber, separate from retailerConfig.phoneNumber, because sometimes we'll
    // override this later with the Provider phone number.
    if (retailerConfig?.phoneNumber) {
      setDisplayPhoneNumber(retailerConfig.phoneNumber);
    }
  }, []);
  /**
   * Returns category name by categoryId
   * @param categoryId
   */
  const getCategoryNameById = (categoryId: number): string =>
    categories.find((c) => c.categoryId === categoryId)?.categoryName || '';

  return (
    <AppContext.Provider
      value={{
        categories,
        setCategories,
        srcPartnerId: srcPartnerId,
        getCategoryNameById,
        features,
        setFeatures,
        setSrcPartnerId,
        displayPhoneNumber,
        setDisplayPhoneNumber,
        retailerConfig,
        setRetailerConfig,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
